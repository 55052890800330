import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import ProfileEditionForm from "@application/Components/settings/profiles/ProfileEditionForm";
import ProfileList from "@application/Components/settings/profiles/ProfileList";
import ProfileController from "@application/Controllers/ProfileController";
import SnackbarHelper from "@application/Helpers/SnackbarHelper";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import SimpleTextSearchFilter from "@infrastructure/components/interface/search/SimpleTextSearchFilter";
import { type DtoProfile, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import { Box, Pagination, PaginationItem, Stack, useTheme } from "@mui/material";
import { FilteringOperator, PAGE, PAGE_SIZE, PaperTitle, Typography, getButton } from "@mykey4/core";

export const Profiles = () => {
	const component = "profiles";

	const { client, event } = useContextModule();
	const theme = useTheme();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [page, setPage] = useState(PAGE);
	const [pageSize] = useState(PAGE_SIZE);
	const [totalRowCount, setTotalRowCount] = useState(0);

	const [sorts] = useState<Array<string>>([]);
	const [search, setSearch] = useState<string>("");

	const [modalFormIsOpen, setModalFormIsOpen] = useState(false);

	const [isNew, setIsNew] = useState(false);
	const [profile, setCurrentProfile] = useState<DtoProfile | undefined>();
	const [profiles, setProfiles] = useState<Array<DtoProfile>>([]);

	const translations = {
		title: t("old.registration.settings.profiles.index.title"),
		editionModal: {
			title: t(`old.registration.settings.profiles.edition.title.${isNew ? "creation" : "modification"}`),
			returnMessages: {
				success_creation: t("old.registration.settings.profiles.edition.returnMessages.success_creation"),
				success_modification: t("old.registration.settings.profiles.edition.returnMessages.success_modification"),
				success_deletion: t("old.registration.settings.profiles.edition.returnMessages.success_deletion"),
			},
		},
		buttons: {
			add: t("old.form.buttons.add"),
		},
		error: t("old.common.errors.generic"),
	};

	const handleChangeFilter = (_value: string) => {
		setSearch(_value);
		setPage(PAGE);
	};

	const handleChangePage = (_: React.ChangeEvent<unknown>, _page: number) => {
		setPage(_page);
	};

	const handleSaveProfil = (_isNew: boolean, _profile: DtoProfile) => {
		const action = _isNew ? ProfileController.create : ProfileController.update;
		action(client.id, event.id, _profile)
			.then((response) => {
				if (response) {
					sendSuccess(translations.editionModal.returnMessages[_isNew ? "success_creation" : "success_modification"]);
				} else {
					sendError(translations.error);
				}
			})
			.catch((error) => {
				sendError(error?.status === 422 ? error?.message?.[0]?.technicalMessage : error);
			})
			.finally(() => {
				setModalFormIsOpen(false);
				getProfiles();
			});
	};

	const handleDeleteProfile = async (id: string) => {
		ProfileController.deleteEntity(client.id, event.id, id)
			.then((response) => {
				if (response) {
					sendSuccess(translations.editionModal.returnMessages.success_deletion);
				} else {
					sendError(translations.error);
				}
			})
			.catch((error) => sendError(error))
			.finally(() => {
				getProfiles();
			});
	};

	const handleModalFormOpen = (_isNew: boolean, _profile?: DtoProfile) => {
		setModalFormIsOpen(true);
		setIsNew(_isNew);
		setCurrentProfile(undefined);
		if (!_isNew && _profile) {
			setCurrentProfile(_profile);
		}
	};

	const handleModalFormClose = () => {
		setModalFormIsOpen(false);
	};

	const getProfiles = useCallback(() => {
		const fetchData = async (clientId: string, operationId: string) => {
			const filters = ["name", FilteringOperator.CaseInsensitiveContain, search].join("");
			ProfileController.getListPagined(clientId, operationId, filters, sorts, {
				page,
				pageSize,
			})
				.then((response) => {
					const { data: responseProfiles, pagination: responsePagination } = response;
					if (responsePagination.totalCount / responsePagination.pageSize < responsePagination.page) {
						setPage(PAGE);
					}
					setTotalRowCount(responsePagination.totalCount);
					setProfiles(responseProfiles);
				})
				.catch((error) => enqueueSnackbar(error, SnackbarHelper.error));
		};

		fetchData(client.id, event.id);
	}, [event, page, pageSize, search, sorts]);

	useEffect(() => {
		getProfiles();
	}, [getProfiles]);

	return (
		<>
			<DefaultModal open={modalFormIsOpen} title={translations.editionModal.title}>
				<ProfileEditionForm isNew={isNew} profile={profile} handleSaveName={handleSaveProfil} handleModaleClose={handleModalFormClose} />
			</DefaultModal>
			<PaperTitle
				title={{
					icon: {
						name: "file-user",
						variant: "h4",
						color: theme.palette.primary.light,
					},
					children: <Typography variant="h4">{t(`${component}.title`)}</Typography>,
					buttonGroup: {
						buttons: [
							{
								onClick: () => handleModalFormOpen(true),
								...getButton({ type: "add" }),
							},
						],
					},
				}}
			>
				<Stack spacing={1}>
					<SimpleTextSearchFilter handleChange={handleChangeFilter} />
					<ProfileList profiles={profiles} handleOpenModalForm={handleModalFormOpen} handleDelete={handleDeleteProfile} />

					<Box display={"flex"} justifyContent={"flex-end"} sx={{ py: 1 }}>
						<Pagination
							page={page}
							count={Math.ceil(totalRowCount / pageSize)}
							size="small"
							onChange={handleChangePage}
							renderItem={(item) => <PaginationItem {...item} />}
						/>
					</Box>
				</Stack>
			</PaperTitle>
		</>
	);
};
