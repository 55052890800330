import { type ParticipantRead, type PathEntity, type ServiceArgs, get } from "@mykey4/core";

const module = "registration";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "participants", id }];

interface GetParticipantArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	participantId: string;
}

export const getParticipant = async (args: GetParticipantArgs): Promise<ParticipantRead> => {
	const { participantId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(participantId),
		...others,
	});
};
