import { t } from "i18next";
import { useEffect, useState } from "react";

import type { IGridModel } from "@infrastructure/model/@types/components/datagrid";
import GetParticipantsGridModel from "@infrastructure/model/grids/Participants.Grid.Model";
import { type DtoParticipantGet, useContextModule } from "@key4-front-library/core";
import { Grid, TablePagination } from "@mui/material";
import { DataGridPro, type GridInputRowSelectionModel, type GridSortModel, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { Link } from "@mykey4/core";
import { getPath } from "@routes";

interface Props {
	isLoading: boolean;
	isReloading: boolean;
	page: number;
	pageSize: number;
	totalRowCount: number;
	handleChangePage: (page: number) => void;
	handleChangePageSize: (pageSize: number) => void;
	sorts: GridSortModel;
	handleChangeSortCriteria: (criteria: GridSortModel) => void;
	rowsPerPageOptions: Array<number>;
	selection: GridInputRowSelectionModel;
	handleChangeSelection?: (newSelection: GridInputRowSelectionModel) => void;
	participants: Array<DtoParticipantGet>;
}

const ParticipantList = (props: Props) => {
	const {
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		sorts,
		handleChangeSortCriteria,
		rowsPerPageOptions,
		selection,
		handleChangeSelection,
		participants,
	} = props;

	const [dataGridModel, setDataGridModel] = useState<IGridModel>();
	const { client, event } = useContextModule();

	useEffect(() => {
		const participantDefinitionModel: any = {
			id: t("old.registration.participants.model.id.label"),
			key: t("old.registration.participants.model.key.label"),
			firstname: t("old.registration.participants.model.firstname.label"),
			lastname: t("old.registration.participants.model.lastname.label"),
			email: t("old.registration.participants.model.email.label"),
			invitationStatus: t("old.registration.participants.model.invitation-status.label"),
		};
		const participantsGridModel = GetParticipantsGridModel(participantDefinitionModel);
		setDataGridModel(participantsGridModel);
	}, [t]);

	const CustomPagination = () => {
		const apiRef = useGridApiContext();
		const _page = useGridSelector(apiRef, gridPageSelector);

		return (
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
					handleChangePage(newPage);
				}}
				page={_page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={rowsPerPageOptions}
				onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(event.target.value, 10));
				}}
				showFirstButton
				showLastButton
			/>
		);
	};

	return (
		<Grid container direction={"row"}>
			<Grid item style={{ width: "100%", overflow: "auto" }}>
				{dataGridModel && (
					<DataGridPro
						initialState={{
							...dataGridModel.initialState,
						}}
						autoHeight={true}
						checkboxSelection={false}
						rowSelectionModel={selection}
						pagination={true}
						paginationMode="server"
						sortingMode="server"
						paginationModel={{ page, pageSize }}
						columns={dataGridModel.columnsDefinition}
						rows={participants.map((participant) => ({
							...participant,
							key: <Link to={getPath("participantDetails", [client.key, event.key, participant.id])}>{participant.key}</Link>,
						}))}
						rowCount={totalRowCount}
						pageSizeOptions={rowsPerPageOptions}
						editMode={"row"}
						onRowSelectionModelChange={handleChangeSelection}
						sortModel={sorts}
						onSortModelChange={handleChangeSortCriteria}
						disableVirtualization
						components={{
							Pagination: CustomPagination,
						}}
						density="compact"
						sx={{
							"& .MuiDataGrid-cell:hover": {
								cursor: "pointer",
							},
						}}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default ParticipantList;
