import type { EntityPath, PageType } from "@routes";

export const getPath = (type: PageType, keys?: Array<string>): string => generatePath(getPathEntities(type, keys));

const generatePath = (pathEntities: Array<EntityPath>): string => {
	let baseUrl = pathEntities
		.map((entity) => {
			if (entity.entity) {
				return entity.id ? `${entity.entity}/${entity.id}` : entity.entity;
			}
			return entity.id ? entity.id : "";
		})
		.join("/");

	if (baseUrl.endsWith("/")) {
		baseUrl = baseUrl.slice(0, -1);
	}

	return `/${baseUrl}`;
};

const getPathEntities = (type: PageType, keys?: Array<string>): Array<EntityPath> => {
	switch (type) {
		case "advancedSettings":
			return [{ id: keys?.[0] ?? ":clientKey" }, { id: keys?.[1] ?? ":eventKey" }, { entity: "advancedSettings" }];
		case "participantDetails":
			return [{ id: keys?.[0] ?? ":clientKey" }, { id: keys?.[1] ?? ":eventKey" }, { entity: "participants" }, { id: keys?.[2] ?? ":participantId" }];
	}
};
