import { getCellIcon, getCellLabel } from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";
import { capitalizeStr, getColumnAction } from "@mykey4/core";

const datagridHeaders: Array<GridColDef> = [
	{ field: "id" },
	{
		field: "type",
		minWidth: 250,
		disableColumnMenu: true,
		valueGetter: (params) => {
			return capitalizeStr(params.value as string);
		},
	},
	{
		field: "iconImage",
		maxWidth: 55,
		headerAlign: "center",
		align: "center",
		hideSortIcons: true,
		disableColumnMenu: true,
		renderCell: getCellIcon,
	},
	{
		field: "name",
		minWidth: 250,
		disableColumnMenu: true,
		flex: 1,
		renderCell: getCellLabel,
	},
	getColumnAction(),
];

const Roles = {
	datagridHeaders,
};

export default Roles;
